import React from 'react';
import { Link } from 'react-router-dom';

function ServicesHomeOne({ className }) {
    return (
        <section className={`serviceHome pt-90 pb-100 ${className}`} id="story">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-9">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">
                                The Lost Soul Island
                            </h3>
                            <p>A collection of 10,000 unique “manbeast” creatures based on the popular story behind “The Island of Dr. Moreau”. Ownership of an NFT gives access to our VR game, and successful completion of the game gives access to the City of Life Metaverse. Rent properties, buy plots of land, set up stores to sell your art, and much more. Come and join the revolt and reap the rewards!</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 text-center">
                        {/* <button type="button" className="main-btn btn-gradient hvr-pulse-grow">
                            Get Early Access!
                        </button> */}
                        <div className="">
                            <Link
                                className="main-btn btn-gradient hvr-pulse-grow"
                                to={{ pathname: "https://discord.gg/yvKDkuN8T5" }} target="_blank" >Get Early Access</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
