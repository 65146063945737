import React from 'react';

function Roadmap() {
    return (
        <>
            <section className="appie-team-area pt-100 mt-50  pb-100" id="roadmap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Roadmap</h3>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="timeline">
                                <li>
                                    <div className="direction-l">
                                        <span className="flag">Social Media Launch</span>
                                    </div>
                                    <div className="direction-r">
                                        <div className="">Launching the official Lost Soul Island social media accounts!</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="direction-l">
                                        <span className="flag">Social Media Launch</span>
                                    </div>
                                    <div className="direction-r">
                                        <div className="">Launching the official Lost Soul Island social media accounts!</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="direction-l">
                                        <span className="flag">Social Media Launch</span>
                                    </div>
                                    <div className="direction-r">
                                        <div className="">Launching the official Lost Soul Island social media accounts!</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="direction-l">
                                        <span className="flag">Social Media Launch</span>
                                    </div>
                                    <div className="direction-r">
                                        <div className="">Launching the official Lost Soul Island social media accounts!</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="direction-l">
                                        <span className="flag">Social Media Launch</span>
                                    </div>
                                    <div className="direction-r">
                                        <div className="">Launching the official Lost Soul Island social media accounts!</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Roadmap;
