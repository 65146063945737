import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer'; 
import FaqHomeOne from './FaqHomeOne'; 
import FooterHomeOne from './FooterHomeOne';
import HeroHomeOne from './HeroHomeOne';
import HomeOneHeader from './HomeOneHeader'; 
import ServicesHomeOne from './ServicesHomeOne';
import Roadmap from './Roadmap'; 
import TeamHomeOne from './TeamHomeOne'; 
import ShowCaseHomeThree from './ShowCaseHomeThree';

function HomeOne() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader action={drawerAction.toggle} />
            <HeroHomeOne />
            <ServicesHomeOne />
            <ShowCaseHomeThree />
            <Roadmap />
            <TeamHomeOne />
            <FaqHomeOne />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default HomeOne;
