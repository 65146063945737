import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import twitter from '../../assets/images/twitter.png';
import insta from '../../assets/images/insta.png';
import git from '../../assets/images/git.png';

function Drawer({ drawer, action }) {

    return (
        <>
            <div
                onClick={(e) => action(e)}
                className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
            ></div>
            <div className="offcanvas_menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className={`offcanvas_menu_wrapper ${drawer ? 'active' : ''}`}>
                                <div className="canvas_close">
                                    <a href="#" onClick={(e) => action(e)}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                </div>
                                <div className="offcanvas-brand text-center mb-40">
                                    <img src={logo} alt="" />
                                </div>
                                <div id="menu" className="text-left ">
                                    <ul className="offcanvas_main_menu">
                                        <li>
                                            <a href="/#story" >Story</a>
                                        </li>
                                        <li>
                                            <a href="/#roadmap" >Roadmap</a>
                                        </li>
                                        <li>
                                            <a href="/#team" >Team</a>
                                        </li>
                                        <li>
                                            <a href="/#faq" >Faq</a>
                                        </li>
                                        <li className="hvr-pop social_ico">
                                            <Link
                                                to={{ pathname: "https://twitter.com/LostSoulIsland" }}
                                                target="_blank"><img src={twitter} alt="" /></Link>
                                        </li>
                                        <li className="hvr-pop social_ico">
                                            <Link to="/"><img src={insta} alt="" /></Link>
                                        </li>
                                        <li className="hvr-pop social_ico">
                                            <Link to="/"><img src={git} alt="" /></Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Drawer;
