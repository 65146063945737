import React from 'react';
import LoaderImg from '../../assets/images/loader.svg';
import LogoImg from '../../assets/images/logo.png';

function Loader() {
    return (
        <>
            <img src={LoaderImg} alt="" />
            <img src={LogoImg} alt="" style={{ width: "200px" }} />

        </>
    );
}

export default Loader;
