import React from 'react';
import { Link } from 'react-router-dom';
import logosymbol from '../../assets/images/logo-symbol.png';
import logotext from '../../assets/images/logo-text.png';

function FooterHomeOne({ className }) {
    return (
        <>
            <section className={`footerSection mb-50 mt-100 pt-50 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6">
                                <div className="logo">
                                    <a href="#">
                                        <img src={logosymbol} alt="" style={{width: "50px"}} />
                                    </a>
                                </div> 
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className=""> 
                                <Link 
                                    className="main-btn btn-gradient hvr-pulse-grow"
                                    to={{  pathname: "https://discord.gg/yvKDkuN8T5" }} target="_blank" >Join Our Discord</Link>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                                <div className="text-right pt-10">
                                    <a href="#">
                                        <img src={logotext} alt="" style={{width: "200px"}}  />
                                    </a>
                                </div> 
                        </div>
                    </div>
                    <div className="row mt-80">
                        <div className="col-lg-12 text-center">
                            <p>2022 All Rights Reserved The Lost Soul Island Collection</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeOne;
