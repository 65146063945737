import React from 'react';
import { Link } from 'react-router-dom';
import twitter from '../assets/images/twitter.png';
import discord from '../assets/images/discord.png';

function Navigation() {
    return (
        <>
            <ul>
                <li>
                    <a href="/#story" >Story</a>
                </li>
                <li>
                    <a href="/#roadmap" >Roadmap</a>
                </li>
                <li>
                    <a href="/#team" >Team</a>
                </li>
                <li>
                    <a href="/#faq" >Faq</a>
                </li>
                <li className="hvr-pop social_ico">
                    <Link
                        to={{ pathname: "https://twitter.com/LostSoulIsland" }}
                        target="_blank"><img src={twitter} alt="" /></Link>
                </li>
                <li className="hvr-pop social_ico">
                    <Link
                        to={{ pathname: "https://discord.gg/yvKDkuN8T5" }}
                        target="_blank"><img src={discord} alt="" /></Link>
                </li>

            </ul>
        </>
    );
}

export default Navigation;
