import React from 'react';
import { Link } from 'react-router-dom';
import Nft1Img from '../../assets/images/nft1.png';
import Timer from '../Helper/Timer';

function HeroHomeOne() {
    return (
        <>
            <section className="appie-hero-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 order-2 order-md-1">
                            <div className="appie-hero-content">
                                <h1 className="appie-title">
                                    The Lost Soul Island Collection
                                </h1>
                                <p>
                                    An exclusive NFT collection based on “The Island of Dr. Moreau” movie, the exclusive man animals return in full force in the City of Life Metaverse.
                                </p>
                                <ul>
                                    <li> 
                                        <Link 
                                        className="main-btn btn-gradient hvr-pulse-grow"
                                        to={{  pathname: "https://discord.gg/yvKDkuN8T5" }} target="_blank" >Join the whitelist!</Link>
                            
                                        <div className='mt-4'>
                                            <Timer />
                                        </div>

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 order-md-2 order-1">
                            <div className="appie-hero-thumb">
                                <div
                                    className="thumb wow animated fadeInUp bannerImg"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    <img src={Nft1Img} alt="" className='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroHomeOne;
