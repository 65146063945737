import { useEffect, useState } from "react";

export default function Countdown() {


    const [timerDays, setTimerDays] = useState(0);
    const [timerHours, setTimerHours] = useState(0);
    const [timerMinutes, setTimerMinutes] = useState(0);


    let interval;

    const startTimer = () => {
        const countdownDate = new Date('October 28, 2022 00:00:00').getTime();

        interval = setInterval(() => {
            const now = Date.now();
            const difference = countdownDate - now;

            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / 1000 / 60) % 60);
            if (difference < 0) {
                clearInterval(interval.current)
            }
            else {
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
            }
        }, 1000)
    };

    useEffect(() => {
        startTimer();
        return () => {
            // clearInterval(interval.current)
        }
    })

    return (

        <div className="timer-app">
            <div className="timer-container">
                <div className="timer">
                    {timerDays < 10 ? `0${timerDays}` : timerDays}
                    <span className="timer-1">DAYS</span>
                </div>

                <div className="timer">
                    {timerHours < 10 ? `0${timerHours}` : timerHours}
                    <span className="timer-1">HOURS</span>
                </div>

                <div className="timer">
                    {timerMinutes < 10 ? `0${timerMinutes}` : timerMinutes}
                    <span className="timer-1">MIN</span>
                </div>
            </div>
        </div>
    )
}